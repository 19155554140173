import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';
import * as Sentry from '@sentry/capacitor';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private navController: NavController,
    private userService: UserService,
    private apiService: ApiService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((res, rej) => {

      var s = route.queryParams["s"];

      this.setToken(s).then((setToken: any) => {
        if (setToken) {
          this.userService.isloggedin = false;
        }
        var params: any = [];
        Object.keys(route.queryParams).forEach(e => {
          params.push(e + "=" + route.queryParams[e]);
        });

        var para = "";
        if (params.length > 0) {
          para = "?" + params.join('&');
        }

        if (this.userService.isloggedin && this.userService.user) {
          res(true);
          // }
          // else if (this.userService.isloggedin && this.userService.user && !this.userService.user.UserBasicId) {
          //   console.log("navigating to onboarding");
          //   this.navController.navigateRoot('/onboarding' + para);
          //   rej(false);
        } else {

          this.userService.getUserToken().then((token: any) => {
            if (token) {
              this.userService.isTokenValid(setToken).then((isValid: any) => {

                this.apiService.validateToken(token).then((rData: any) => {
                  if (rData) {
                    this.userService.isloggedin = true;
                    this.userService.isUserVerified = rData.data.Auth.IsEmailVerified;
                    this.userService.email = rData.data.Auth.Email;
                    this.userService.organisation = rData.data.Organisation;
                    this.userService.subscription = rData.data.Subscription;
                    this.userService.setSubscriptionState(rData.data.subscriptionState);
                    this.userService.isCoreCompleted = rData.data.User.IsCoreCompleted;
                    this.userService.organisationToIntroduce = rData.data.OrganisationIntro;
                    this.userService.setToken(rData.data.Auth.Token).then((tokenSet: any) => {
                      this.userService.setValidity(rData.data.Auth.Validity).then((validityState: any) => {
                        this.userService.setUser(rData.data.User).then((userSet: any) => {
                          if (this.userService.isloggedin && rData.data.User.UserBasicId) {

                            Sentry.setUser({
                              email: this.userService.email,
                              username: this.userService.user.FirstName + " " + this.userService.user.LastName,
                              id: this.userService.user.UserId
                            });

                            this.userService.sendAuthStateChangeNotification(true);
                            res(true);
                            // } else if (this.userService.isloggedin && !rData.data.User.UserBasicId) {
                            //   console.log("navigating to login because token not found");
                            //   this.navController.navigateRoot("/onboarding" + para);
                            //   rej(false);
                          } else {
                            res(true);
                          }
                        }).catch(e => {
                          this.userService.logout();
                          this.navController.navigateRoot('/login?r=' + encodeURIComponent(state.url));
                          rej(false);
                        });
                      }).catch(e => {
                        this.userService.logout();
                        this.navController.navigateRoot('/login?r=' + encodeURIComponent(state.url));
                        rej(false);
                      });
                    }).catch(e => {
                      this.userService.logout();
                      this.navController.navigateRoot('/login?r=' + encodeURIComponent(state.url));
                      rej(false);
                    });
                  } else {
                    // this.router.navigateByUrl("auth"+ para);
                    console.log("navigating to login because no data from url");
                    this.userService.logout();
                    this.navController.navigateRoot('/login?r=' + encodeURIComponent(state.url));
                    rej(false);
                  }
                }).catch(e => {
                  console.log("Something went wrong", e);
                  if (!e.msg) {
                    e.msg = "Something went wrong. Please try again.";
                  }
                  console.log("navigating to login");
                  this.userService.logout();
                  this.navController.navigateRoot('/login?r=' + encodeURIComponent(state.url));
                  rej(false);
                });


                // if(isValid) {
                //   //Token is valid 
                // } else {
                //   //Token is invalid
                // }
              }).catch(e => {
                console.log("navigating to login", e);
                this.userService.logout();
                this.navController.navigateRoot('/login?r=' + encodeURIComponent(state.url));
                rej(false);
              });
            } else {
              console.log("navigating to login because token not found");
              this.userService.logout();
              this.navController.navigateRoot('/login?r=' + encodeURIComponent(state.url));
              rej(false);
            }
          });
        }

      });

    });
  }

  setToken(s: any) {
    return new Promise((r, j) => {
      if (s) {
        var token: any = atob(s);
        // this.userService.setToken()
        this.userService.setToken(token).then((rbody: any) => {
          r(true);
        }).catch(e => {
          r(false);
        });
      } else {
        r(false);
      }
    });
  }

}
