import { Component, Inject, NgZone, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { IonRouterOutlet, Platform } from '@ionic/angular';
// import { IonRouterOutlet, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
// import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavigationEnd, Router } from '@angular/router';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token, } from '@capacitor/push-notifications';
import { CommonService } from './shared/services/common.service';
import { LocalNotifications, LocalNotificationSchema, ActionPerformed as ActionPerformed2 } from '@capacitor/local-notifications';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from './shared/services/api.service';
import { UserService } from './shared/services/user.service';
declare var gtag: any;
declare let fbq: Function;

import { register } from 'swiper/element/bundle';
// import * as Sentry from '@sentry/capacitor';
// import * as SentryAngular from '@sentry/angular-ivy';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private storage: Storage,
    private platform: Platform,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private zone: NgZone,
    private router: Router,
    private apiService: ApiService,

    @Inject(PLATFORM_ID) private platformId: Object,
    private common: CommonService,
    @Optional() private routerOutlet?: IonRouterOutlet,
    // private routerOutlet: IonRouterOutlet
  ) {
    this.storage.create();
  }
  async ngOnInit() {
    await SplashScreen.hide();
    this.platform.ready().then(async () => {

      this.matIconRegistry.addSvgIcon(
        "content_play",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/play-icon.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "content_adioscope",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/content-contemplation.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "content_journaling",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/content-journaling.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "content_locked",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/content-locked.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "content_completed",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/content-completed.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "forward_arrow",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/forward-arrow.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "adios_menu",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/menu.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "head_icon",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/head-tab-icon-2.svg")
      )

      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {

          if (isPlatformBrowser(this.platformId)) {
            gtag('event', 'page_view', {
              page_path: event.urlAfterRedirects
            });
            // fbq('track', 'PageView');
          }
          /** END */
        });

      this.common.canUseNative().then((isNative: any) => {
        if (isNative) {

          // Request permission to use push notifications
          // iOS will prompt user and return if they granted permission or not
          // Android will just grant without prompting
          PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
              // Register with Apple / Google to receive push via APNS/FCM
              PushNotifications.register();
            } else {
              // Show some error
            }
          });

          PushNotifications.addListener('registration', (token: Token) => {
            // alert('Push registration success, token: ' + token.value);
            this.apiService.setFcmToken(token.value).then((res: any) => {
              console.log('Push registration success, token: ' + token.value);
            }).catch(e => {

            });
          });

          PushNotifications.addListener('registrationError', (error: any) => {
            // alert('Error on registration: ' + JSON.stringify(error));
          });

          PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
              LocalNotifications.schedule({
                notifications: [{
                  id: 1,
                  title: notification.title,
                  body: notification.body,
                  extra: notification.data,
                  smallIcon: "ic_stat_app_icon",
                }]
              });

              // this.localNotifications.schedule({
              //   id: 1,
              //   title: notification.title,
              //   text: notification.body,
              //   data: notification.data
              // });
              // alert('Push received: ' + JSON.stringify(notification));
            },
          );

          PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
              // alert('Push action performed: ' + JSON.stringify(notification));
              this.processNotification(notification.notification.data);
            },
          );

          LocalNotifications.addListener('localNotificationReceived', (notification: LocalNotificationSchema) => {
            // alert('Push action performed: ' + JSON.stringify(notification));
          });
          LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction: ActionPerformed2) => {
            // alert('Push action performed: ' + JSON.stringify(notificationAction));
            this.processNotification(notificationAction.notification.extra);
          });

        } else {
          FirebaseAnalytics.initializeFirebase(environment.firebase);
        }
      });

      this.platform.backButton.subscribeWithPriority(-1, () => {
        if (!this.routerOutlet.canGoBack()) {
          App.exitApp();
        }
      });

      // this.deeplinks.route({
      //   '/dashboard/programs': ProgramsPage,
      //   '/adioscope/view/:situationId': ViewPage,
      // }).subscribe(match => {
      //   // match.$route - the route we matched, which is the matched entry from the arguments to route()
      //   // match.$args - the args passed in the link
      //   // match.$link - the full link data
      //   console.log('Successfully matched route', match);
      // }, nomatch => {
      //   // nomatch.$link - the full link data
      //   console.error('Got a deeplink that didn\'t match', nomatch);
      // });

      // if(environment.production == true) {
      //   Sentry.init({
      //     dsn: environment.sentry,
      //     debug: !environment.production,
      //     release: environment.appVersion,
      //     environment: environment.production ? 'production' : 'development',
      //     initialScope: {
      //     },
      //     integrations: [
      //       new SentryAngular.BrowserTracing({
      //         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //         // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      //         routingInstrumentation: SentryAngular.routingInstrumentation,
      //       }),
      //       // new SentryAngular.Replay({
      //       //   maskAllText: true,
      //         // blockAllMedia: true

      //       // }),
      //     ],
      //     // Performance Monitoring
      //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
      //     // Session Replay
      //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      //   },
      //     // Forward the init method from @sentry/angular-ivy
      //     SentryAngular.init
      //   );
      // }
      
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = environment.appdomain;
        const pathArray = event.url.split(domain);
        // The pathArray is now like ['https://devdactic.com', '/details/42']

        // Get the last element with pop()
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  processNotification(data: any) {
    this.zone.run(() => {
      if (data.type == "SHOW_CONTENT") {
        this.router.navigateByUrl('/content/' + data.alias + '/' + data.pId + '/' + data.bId + '/' + data.cId);
        // this.router.navigateByUrl('/dashboard/programs?pId=' + data.pId + '&ci=' + data.ci + '&l=' + data.l + '&cg=' + data.cg + '&c=' + data.c)
      } else if (data.type == "ALL_CONTEMPLATIONS") {
        this.router.navigateByUrl('/dashboard/contemplations');
      } else if (data.type == "OPEN_CONTEMPLATION") {
        this.router.navigateByUrl('/contemplations/' + data.gcId);
      }
    });
  }

}
