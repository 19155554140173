import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-video-recorder',
  templateUrl: './video-recorder.component.html',
  styleUrls: ['./video-recorder.component.scss'],
})
export class VideoRecorderComponent {

  @Input('uploadedUrl') uploadedUrl : any;

  @Output('videoUploaded') videoUploaded : EventEmitter<any> = new EventEmitter<any>();;

  videoFile: string;
  countdown: number = 0;
  isRecording: boolean = false;
  recorded: boolean = false;
  recordingTime: number = 0;
  private mediaRecorder: MediaRecorder;
  private chunks: BlobPart[] = [];
  private recordInterval: any;


  constructor(
    private platform: Platform,
    private changeRef:ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private apiService: ApiService,
  ) { }

  startCountdown() {
    this.countdown = 3;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.recordVideo();
      }
    }, 1000);
  }


  recordVideo() {
    if (this.platform.is('cordova')) {
        
    } else {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
        const videoElement = document.querySelector('video#recording') as HTMLVideoElement;
        videoElement.srcObject = stream;
        videoElement.volume = 0.0;
        videoElement.controls = false;
        videoElement.play();

        this.mediaRecorder = new MediaRecorder(stream);
        this.chunks = [];
        this.recordingTime = 0;
        this.isRecording = true;

        this.recordInterval = setInterval(() => {
          this.recordingTime++;
        }, 1000);

        this.mediaRecorder.ondataavailable = e => this.chunks.push(e.data);
        this.mediaRecorder.onstop = () => {
          clearInterval(this.recordInterval);
          const blob = new Blob(this.chunks, { type: 'video/mp4' });
          const videoURL = URL.createObjectURL(blob);
          this.videoFile = videoURL;
          this.recorded = true;
          this.isRecording = false;
          this.changeRef.detectChanges();
        };
        
        this.mediaRecorder.start();
      }).catch(error => {
        console.error('Error accessing media devices.', error);
      });
    }
  }

  stopRecording() {
    if (this.isRecording && this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
  }

  replayVideo() {
    if (this.videoFile) {
      const videoElement = document.querySelector('video#playback') as HTMLVideoElement;
      videoElement.src = this.videoFile;
      videoElement.play();
    }
  }

  recaptureVideo() {
    this.videoFile = null;
    this.isRecording = false;
    this.recorded = false;
    this.startCountdown();
  }

  uploadVideo() {
    if (this.platform.is('cordova')) {
      // Mobile platform upload
      // Implement the file upload logic here
    } else {
      // Web platform upload
        fetch(this.videoFile)
        .then(res => res.blob())
        .then(blob => this.apiService.uploadContentVideoFile(blob, 'recorded-video.mp4').then((r: any) => {
          var url = r.data.urls[0];
          this.uploadedUrl = url + environment.sas;
          this.videoUploaded.emit(url);
        }).catch(e => {
          if(!e.msg) {
            e.msg = "Something went wrong";
          }
          this.snackbar.open(e.msg);
        }))
        .catch(err => console.error(err));
    }
  }
}
