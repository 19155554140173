import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Event, NavigationStart, Router } from '@angular/router';
// import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { CurriculumService } from '../../services/curriculum.service';
declare var jwplayer: any;
import { CapacitorMusicControls } from 'capacitor-music-controls-plugin';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

export interface ContentFlowOptions {
  programParticipant: any,
  program: any,
  chapterIndex: number,
  lessonIndex: number,
  contentGroupIndex: number,
  programId: number,
  contentIndex: number,
  Id?: number
}

@Component({
  selector: 'sb-content-flow',
  templateUrl: './content-flow.component.html',
  styleUrls: ['./content-flow.component.scss']
})
export class ContentFlowComponent implements OnInit {

  // @Output('close') close: EventEmitter<any> = new EventEmitter();
  @Output('markCompleted') markCompleted: EventEmitter<any> = new EventEmitter();
  @Output('close') close: EventEmitter<any> = new EventEmitter();
  @Input('options') options?: ContentFlowOptions;
  @Input('programParticipant') programParticipant?: any;
  @Input('contents') contents?: any = [];
  @Input('progress') progress?: any = {};
  @Output('toggleFavorite') triggerToggleFavorite: EventEmitter<any> = new EventEmitter();
  @Input('isFavorite') isFavorite ?: boolean = false;

  previousContentOptions?: any | ContentFlowOptions;
  nextContentOptions?: any | ContentFlowOptions;

  loadingContent: boolean = true;
  completing: boolean = false;
  loading: boolean = false;


  showjournal: boolean = false;

  // currentChapter: any;
  // currentLesson: any;
  // currentContentGroup: any;
  currentContent: any;

  currentContentIndex: any;

  previousContentTitle: string = "Previous";
  nextContentTitle: string = "Next";

  hasPrevious: boolean = false;
  hasNext: boolean = false;

  state: any;

  constructor(
    public audioService: CurriculumService,
    public modal: ModalController,
    public apiService: ApiService,
    public snackbar: MatSnackBar,
    public router: Router,
    public userService: UserService,
    private common: CommonService,
    private _sanitizer: DomSanitizer,
    // private musicControls: MusicControls
  ) {

    this.audioService.stop();
    this.audioService.unsetTrack();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.stop();
      }
    });
  }

  ngOnInit(): void {
    this.audioService.getState().subscribe(t => {
      this.state = t;
      console.log(t);
        var timeRemaining = 16;
        if (t.duration) {
          timeRemaining = t.duration - t.currentTime;
        }
      if(this.state.type == 'ended' || (this.state.type == 'timeupdate' && timeRemaining <= 15)) {
        this.markContentAsComplete(this.currentContent);
      }
    });

    this.contents.forEach((content: any, index: any) => {
      if (
        content.Id == this.options?.Id
        // content.programId == this.options?.programId &&
        // content.chapterIndex == this.options?.chapterIndex &&
        // content.lessonIndex == this.options?.lessonIndex &&
        // content.contentGroupIndex == this.options?.contentGroupIndex &&
        // content.contentIndex == this.options?.contentIndex
      ) {
        this.currentContentIndex = index;
        this.setContent();
      }
    });



    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {

        let handleControlsEvent = (action) => {
          const message = JSON.parse(action).message;
          console.log("Music Control message is: ", message);
          switch (message) {
            case 'music-controls-next':
              
              break;
            case 'music-controls-previous':
              
              break;
            case 'music-controls-pause':
              this.pause();
              break;
            case 'music-controls-play':
              this.resume();
              break;
            case 'music-controls-destroy':
              this.stop();
              break;
            // External controls (iOS only)
            case 'music-controls-toggle-play-pause':
              if (this.state?.paused || this.state?.canplay) {
                this.resume();
              } else {
                this.pause();
              }
              break;
            case 'music-controls-seek-to':
                const seekToInSeconds = JSON.parse(action).position;
                CapacitorMusicControls.updateElapsed({
                  elapsed: seekToInSeconds,
                  isPlaying: true
                });
                this.onSliderChangeEnd({
                  value: seekToInSeconds
                });
              break;
            case 'music-controls-skip-forward':
              // alert("Music Control Skip Forward");
              break;
            case 'music-controls-skip-backward':
              // alert("Music Control Skip Backward");
              break;

            // Headset events (Android only)
            // All media button events are listed below
            case 'music-controls-media-button':
              // Do something
                if (this.state?.paused || this.state?.canplay) {
                  this.resume();
                } else {
                  this.pause();
                }
              break;
            case 'music-controls-headset-unplugged':
              this.pause(); 
              break;
            case 'music-controls-headset-plugged':
              // Do something
              break;
            default:
              break;
          }
        };

        // IOS
        CapacitorMusicControls.addListener('controlsNotification', (info: any) => {
          console.log('controlsNotification was fired');
          console.log(info);
          const message = JSON.parse(info).message;
          // alert(message);
          handleControlsEvent(info);
        });

        // ANDROID (13, see bug above as to why it's necessary)
        document.addEventListener('controlsNotification', (event: any) => {
          console.log('controlsNotification was fired');
          console.log(event);
          // alert(event.message);
          const info = JSON.stringify({ message : event.message, position: 0 });
          handleControlsEvent(info);
        });
      }
    });


  }

  doneForNow(){
    this.close.emit();
  }

  buyBook(currentContent: any) {
    console.log("Current Content: ", currentContent);
    this.common.openLink(currentContent.Url);
  }

  setContent() {
    this.loadingContent = true;
    this.audioService.stop();

    this.currentContent = this.contents[this.currentContentIndex];

    this.apiService.saveEventLog({
      EventType: "ViewProgramContent",
      UserId: this.userService.user.UserId,
      ProgramId: this.programParticipant?.ProgramId,
      ProgramParticipantId: this.programParticipant?.ProgramParticipantId,
      ContentId: this.currentContent.Id,
    });

    this.adioscopeSituation = undefined;
    // this.currentChapter = this.options?.program.ProgramChapters[this.contents[this.currentContentIndex].chapterIndex];
    // this.currentLesson = this.currentChapter?.ProgramLessons[this.contents[this.currentContentIndex].lessonIndex];
    // this.currentContentGroup = this.currentLesson.ContentGroups[this.contents[this.currentContentIndex].contentGroupIndex];
    // this.currentContent = this.currentContentGroup.Contents[this.contents[this.currentContentIndex].contentIndex];
    setTimeout(() => {
      this.loadingContent = false;
      switch (this.currentContent.Type) {
        case "YOUTUBE":
          this.currentContent.Url = this._sanitizer.bypassSecurityTrustResourceUrl(this.currentContent.Url);
          break;
        case "PODCAST":
          // this.currentContent.Url = this._sanitizer.bypassSecurityTrustResourceUrl(this.currentContent.Url);
          break;
        case "VIDEO":
          
          break;
        case "AUDIO":
          this.openAudioFile(this.currentContent.IsIntroduction, this.currentContent);
          // this.setNotificationControl(this.currentContent)
          //   .then((r) => {
          //     // this.currentTrack = track;
          //     // console.log("this.current Track is: ", this.currentTrack.currentPlaylistContentTitle);
          //   });
          break;


      }
    }, 100);
  }

  previousContent() {
    if (this.currentContentIndex > 0) {
      this.currentContentIndex--;

      var base = '/dashboard/programs/content/'+ this.contents[this.currentContentIndex].alias +'/' + this.contents[this.currentContentIndex].programId;
      if(this.contents[this.currentContentIndex].batchId) {
        base += '/' + this.contents[this.currentContentIndex].batchId;
      }

      this.router.navigate([base, this.contents[this.currentContentIndex].Id]);
      // this.setContent();
    }
  }

  nextContent() {
    if (this.currentContentIndex < this.contents.length - 1) {
      this.currentContentIndex++;
      var base = '/dashboard/programs/content/'+ this.contents[this.currentContentIndex].alias +'/' + this.contents[this.currentContentIndex].programId;
      if(this.contents[this.currentContentIndex].batchId) {
        base += '/' + this.contents[this.currentContentIndex].batchId;
      }
      this.router.navigate([base, this.contents[this.currentContentIndex].Id]);
      // this.setContent();
    }
  }

  //Play Audio File
  openAudioFile(isIntroduction: any, t: any) {
    console.log(isIntroduction);
    if (isIntroduction) {
      this.audioService.playContent(t);
      this.setNotificationControl(t);
    } else {
      this.audioService.playContent(t);
      this.setNotificationControl(t);
    }

    // this.audioService.getState().subscribe((t: any) => {
    //   // console.log(t);
    //   var timeRemaining = 0;
    //   if (t.duration) {
    //     timeRemaining = t.duration - t.currentTime;
    //   }
    //   if (t.currentTime  == t.duration || (this.state.playing && timeRemaining < 15)) {
    //     this.markContentAsComplete(this.currentContent);
    //   }
    // });
  }

  //Mark the content as completed or watched.
  markContentAsComplete(content: any) {
    if (this.completing || !this.options || !this.programParticipant || this.progress[content.Id]) {
      return false;
    }
    this.completing = true;
    this.apiService.contentCompleted(content.Id).then((res: any) => {
      if (res.success) {
        this.completing = false;
        this.progress[res.data.contentMeta.Id] = res.data.contentMeta;
        this.markCompleted.emit(res.data.contentMeta);
      } else {
        this.completing = false;
        this.snackbar.open("Unable to update your progress");
      }
    }).catch(e => {
      this.completing = false;
    });
  }

  //Get the video file url
  getVideoFile(content: any) {
    return environment.storagedomain + content.Url + environment.sas;
    // return this.apiService.domain + this.apiService.apis.streamVideoContent + content.Id + '?s=' + btoa(this.userService.getToken());
  }

  //Standard Audio Service Controls
  pause() {
    this.audioService.pause();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        CapacitorMusicControls.updateIsPlaying({
          isPlaying: false
        });
      }
    });
  }
  resume() {
    this.audioService.play();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        CapacitorMusicControls.updateIsPlaying({
          isPlaying: true
        });
        // this.setNotificationControl(this.currentContent);
      }
    });
  }
  stop() {
    this.audioService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        CapacitorMusicControls.destroy();
      }
    });
  }

  onSliderChangeEnd(t: any) {
    this.audioService.seekTo(t.value);
  }
  onVolumeChange(t: any) {
    this.audioService.setVolume(t.value)
  }

  setNotificationControl(track) {
    return new Promise((r, j) => {

      // r(false);
      // return;
      this.common.canUseNative().then((isNative: boolean) => {
        if (isNative) {

          CapacitorMusicControls.destroy().finally(() => {
            CapacitorMusicControls.create({
              track: track?.Title,
              artist: track.chapterName + " - " + track.lessonName,
              isPlaying: false,
              cover: '',
              dismissable: true,
              hasPrev: false,
              hasNext: false,
              hasClose: false,       // show close button, optional, default: false
              hasSkipForward: false,  // show skip forward button, optional, default: false
              hasSkipBackward: false, // show skip backward button, optional, default: false
              hasScrubbing: true, // enable scrubbing from control center and lock screen progress bar, optional,
              skipForwardInterval: 0, 
              skipBackwardInterval: 0,
              playIcon: 'round_play_circle_black_48',
              pauseIcon: 'round_pause_circle_black_48',
              notificationIcon: 'ic_stat_app_icon',
            }).then((v) => {
              console.log("Control", v);
              r(true);
            }).catch((e) => {
              console.log("Control Error", e);
              j(false);
            });
          });
        } else {
          r(true);
        }
      });
    });
  }

  markVideoAsComplete(content: any) {
    if(!content.IsIntroduction){
      this.completing = true;
      this.apiService.contentCompleted(content.Id).then((res: any) => {
        if (res.success) {
          this.completing = false;
          // d();
          // this.modalService.dismissAll();
          // this.contentProgress[res.data.contentMeta.Id] = res.data.contentMeta;
          // if (res.data.programLessonMeta) this.lessonProgress[res.data.programLessonMeta.ProgramLessonId] = res.data.programLessonMeta;
          // if (res.data.programChapterMeta) this.chapterProgress[res.data.programChapterMeta.ProgramChapterId] = res.data.programChapterMeta;
        } else {
          this.completing = false;
          this.snackbar.open("Unable to update your progress");
        }
      }).catch(e => {
        this.completing = false;
      });
    } else {
      
    }
  }

  adioscopeSituation: any;
  setSituation(event: any) {
    this.adioscopeSituation = event;
  }

  videoEnded() {
    console.log("Video Ended");
  }


  ngOnDestroy() {
    this.audioService.pause();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // this.musicControls.destroy();
      }
    });
  }
  changeBooleanValue(assignment: any, event: any) {
    console.log(assignment);
    console.log(event);
  }

  closeDialog(data?: any) {
    this.audioService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // this.musicControls.destroy();
      }
    });
    this.audioService.resetState();
    try {
      this.modal?.dismiss(data);
    } catch {}
    // this.close.emit();
  }


  createSituation() {
    // this.router.navigate([], {
    //   queryParams: {
    //     'pId': null,
    //     'ci': null,
    //     'l': null,
    //     'cg': null,
    //     'c': null
    //   },
    //   queryParamsHandling: 'merge'
    // });

    setTimeout(() => {
      this.router.navigateByUrl('/adioscope/create?apId='
        + this.contents[this.currentContentIndex].programId +
        '&aci=' + (this.contents[this.currentContentIndex].chapterIndex + 1) +
        '&al=' + (this.contents[this.currentContentIndex].lessonIndex + 1) +
        '&acg=' + (this.contents[this.currentContentIndex].contentGroupIndex + 1) +
        '&ac=' + (this.contents[this.currentContentIndex].contentIndex + 1) +
        '&afc=1'
      );
      // this.closeDialog();
    }, 50);
  }


  toggleChat() {
    this.showjournal = !this.showjournal;
  }

  toggleFavorite() {
    this.triggerToggleFavorite.emit();
  }
}
