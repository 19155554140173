<div>
  <ng-container *ngIf="uploadedUrl">
    <p>Uploaded Video</p>
    <p class="muted"><span>{{ uploadedUrl }}</span></p>
  </ng-container>

    <ion-button (click)="startCountdown()" *ngIf="!isRecording && !videoFile">Record Video</ion-button>
    <ion-button (click)="replayVideo()" [disabled]="!videoFile">Replay Video</ion-button>
    <ion-button (click)="recaptureVideo()" [disabled]="!videoFile">Recapture Video</ion-button>
    <ion-button (click)="uploadVideo()" [disabled]="!videoFile">Upload Video</ion-button>
    <p></p>
    <div class="record-container">
      <div *ngIf="countdown > 0" class="countdown">{{ countdown }}</div>
      <video id="recording" autoplay *ngIf="isRecording || !videoFile" style="width: 100%; height: auto; margin:0px;"></video>    
      <div class="spinner-border" *ngIf="!isRecording && !videoFile && recorded"></div>
      <video id="playback" [src]="videoFile" controls *ngIf="videoFile && !isRecording" style="width: 100%; height: auto; margin:0px;"></video>
    </div>
  
    <div *ngIf="isRecording" class="recording-controls">
      <span>Recording... {{ recordingTime }}s</span>
      <ion-button color="danger" (click)="stopRecording()">Stop</ion-button>
    </div>

</div>