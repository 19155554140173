<div class="program-content flex-column dflex w-100 h-100  mw-100">
  <div class="section dflex flex-column w-100 h-100">
    <ng-container *ngIf="loading">
      <div class="row m-0 flex w-100 h-100" style="place-content: center; align-content: center;">
        <div class="col-md-12 text-center" style="padding: 5rem;">
          <p>
            <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading && program">
      <div class="list-box overflow-auto pt-0 pb-0 align-start px-0 flex-1" style="align-self: center;"
        [class.venus]="program.Alias == 'venus'">
        <div class="row m-0 w-100 content-center">

          <!-- <div class="box content-card intro-card grey-space" *ngIf="!isOngoing && batch">
            <h1 class="text-white">Welcome!</h1>
            <mat-list class="module-list">
              <mat-list-item class="content-item">
                <div mat-line *ngIf="batch" class="content-title link text-white text-center">This cohort will start on
                  <strong>{{
                    batch.StartDate | date: 'MMM d, y':
                    dateOffset}} at {{ batch.StartDate | date: 'h:mm a': dateOffset}}</strong>.<br>
                  <ng-container *ngIf="programParticipant">Your learning material will start to unlock when the cohort
                    begins.</ng-container>
                  <ng-container *ngIf="!programParticipant">Please join the cohort to get access to the
                    program.</ng-container>
                  <br>
                </div>
              </mat-list-item>
            </mat-list>
          </div> -->

          <div #contentlist class="col-md-12 col-12 p-0" *ngIf="program.ProgramChapters.length > 0">

            <!-- Slider main container -->
            <swiper-container class="mySwiper" pagination="true" pagination-clickable="true" slides-per-view="auto"
            centered-slides="true" space-between="10" init="false">
              <!-- <div class="swiper-wrapper"> -->
                <ng-container *ngFor="let chapter of program.ProgramChapters; let ci = index;">
                  <swiper-slide class="swiper-slide">
                    <div class="box content-card bg-beige w-100" [attr.id]="'content-' + ci" [class.locked]="chapter.locked">
                      <h1>{{ chapter.Name }}</h1>
                      <mat-list class="module-list">
                        <ng-container *ngFor="let module of chapter.ProgramLessons; let li = index;">
                          <ng-container>
                            <div [attr.id]="'content-' + ci + '-' + li" mat-subheader class="module-name">{{ module.Name }} <small
                                class="ml-1 text-muted"
                                *ngIf="!isMember && !isOngoing && !program.IsCategoryIndroduction && !chapter.IsIntroduction">
                                (MEMBERS ONLY)</small>
                            </div>

                            <ng-container *ngFor="let contentGroup of module.ContentGroups; let cgi = index;">

                              <!-- <div *ngIf="module.Order == 1" mat-subheader class="module-name"
                              [attr.id]="'content-' + ci + '-' + li + '-' + cgi">
                                {{ module.Name }}
                                <small class="ml-1 text-muted"
                                  *ngIf="!isMember && !isOngoing && !program.IsCategoryIndroduction && !chapter.IsIntroduction">(MEMBERS
                                  ONLY)</small>
                              </div> -->

                              <ng-container *ngFor="let content of contentGroup.Contents; let c = index;">
                                <mat-list-item class="content-item">
                                  <div mat-line class="content-title link"
                                    (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)">
                                    {{ content.Title }}
                                    <small>
                                      <span
                                        *ngIf="content.IsLocked && lockedContent && content.ContentId == lockedContent.ContentId && contentGroup.UnlockOn && (isSubscribed || (lastTrial == chapter.ProgramChapterId && isMember && !isSubscribed))"
                                        class="text-white px-2 mt-2 badge bg-info">Unlocks {{ content.UnlockOnRel
                                        }}</span>
                                    </small>
                                    <!-- <small class="badge badge bg-info" *ngIf="content.IsLocked && contentGroup.UnlockOn">Will unlock {{content.UnlockOnRel}} at {{ contentGroup.UnlockOn | date: 'h:mm a'}}</small> -->

                                    <!-- <small class="badge bg-danger" color="primary" *ngIf="content.IsLocked && contentGroup.UnlockOn">(Unlocks on {{ contentGroup.UnlockOn | date: 'MMM d, y'}} at {{ contentGroup.UnlockOn | date: 'h:mm a'}})</small> -->
                                  </div>

                                  <mat-icon end
                                    *ngIf="!content.IsLocked && (contentMetas[content.ContentId] && contentMetas[content.ContentId].IsCompleted)"
                                    mat-list-icon svgIcon="content_completed"
                                    (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                                    class="link">
                                  </mat-icon>

                                  <mat-icon end
                                    *ngIf="(content.Type == 'AUDIO' || content.Type == 'VIDEO' || content.Type == 'YOUTUBE' || content.Type == 'PODCAST') && !content.IsLocked && (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                                    mat-list-icon svgIcon="content_play"
                                    (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                                    class="link">
                                  </mat-icon>

                                  <mat-icon end
                                    *ngIf="(content.Type == 'ADIOSCOPE' || content.Type == 'ADIOSCOPEEMBED') && !content.IsLocked && (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                                    mat-list-icon svgIcon="content_adioscope"
                                    (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                                    class="link">
                                  </mat-icon>

                                  <mat-icon end *ngIf="(content.Type == 'JOURNALING' || content.Type == 'BUYBOOK' || content.Type == 'COMMUNITY' || content.Type == 'ESSAY') && 
                                    !content.IsLocked &&
                                    (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                                    mat-list-icon svgIcon="content_journaling"
                                    (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                                    class="link">
                                  </mat-icon>

                                  <mat-icon end *ngIf="content.IsLocked" mat-list-icon svgIcon="content_locked">
                                  </mat-icon>
                                </mat-list-item>
                              </ng-container>
                              <mat-list-item *ngIf="contentGroup.Contents.length == 0" class="content-card">
                                <div mat-line class="content-title">No content for this day. Please contemplate on
                                  things that matter</div>
                              </mat-list-item>

                            </ng-container>
                          </ng-container>

                        </ng-container>
                      </mat-list>
                      <div *ngIf="chapter.locked" class="lock-overlay">
                        <mat-icon svgIcon="content_locked"class="link mb-3"></mat-icon>
                        <p>{{ chapter.Name }} will unlock {{ chapter.UnlockOnRel }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                </ng-container>
              <!-- </div> -->

            </swiper-container>

            <div class="d-flex w-100 align-center place-center px-4">
              <app-back-btn [class.d-none]="!swiperInstance" type="button" class="button-prev">
                <span class="d-none d-md-block"></span>
              </app-back-btn>

              <div class="pagination">

              </div>
              <app-adios-btn [addmargin]="false" [class.d-none]="!swiperInstance" type="button" class="button-next">
                <span class="d-none d-md-block"></span>
              </app-adios-btn>
            </div>
            <!-- <div class="swiper-pagination"></div> -->


            <!-- <swiper-container #swiper slidesPerView="auto" [centeredSlides]="true" [spaceBetween]="30" [keyboard]="true" [pagination]="true" [modules]="swiperModules">
              <ng-container *ngFor="let chapter of program.ProgramChapters; let ci = index;">
                <swiper-slide class="swiper-slide bg-grey">
                  <div class="box content-card grey-space">
                    <h1>{{ chapter.Name }}</h1>
                    <mat-list class="module-list">
                      <ng-container *ngFor="let module of chapter.ProgramLessons; let li = index;">
                        <ng-container>
                          <div *ngIf="module.Order > 1" mat-subheader class="module-name">{{ module.Name }} <small
                            class="ml-1 text-muted"
                            *ngIf="!isMember && !isOngoing && !program.IsCategoryIndroduction && !chapter.IsIntroduction">
                            (MEMBERS ONLY)</small>
                          </div>
    
                          <ng-container *ngFor="let contentGroup of module.ContentGroups; let cgi = index;">
                            
                            <div *ngIf="module.Order == 1" mat-subheader class="module-name" id="content-{{ contentGroup.ContentGroupId }}">
                              {{ module.Name }}
                              <small class="ml-1 text-muted" *ngIf="!isMember && !isOngoing && !program.IsCategoryIndroduction && !chapter.IsIntroduction">(MEMBERS ONLY)</small>
                            </div>
    
                            <ng-container *ngFor="let content of contentGroup.Contents; let c = index;">
                              <mat-list-item class="content-item" >
                                <div mat-line class="content-title link"
                                  (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)">
                                  {{ content.Title }} 
                                  <small>
                                    <span *ngIf="content.IsLocked && lockedContent && content.ContentId == lockedContent.ContentId && contentGroup.UnlockOn && (isSubscribed || (lastTrial == chapter.ProgramChapterId && isMember && !isSubscribed))" class="text-white px-2 mt-2 badge bg-info">Unlocks {{ content.UnlockOnRel }}</span>
                                  </small>
                                </div>
    
                                <mat-icon end
                                  *ngIf="!content.IsLocked && (contentMetas[content.ContentId] && contentMetas[content.ContentId].IsCompleted)"
                                  mat-list-icon svgIcon="content_completed"
                                  (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                                  class="link">
                                </mat-icon>
    
                                <mat-icon end
                                  *ngIf="(content.Type == 'AUDIO' || content.Type == 'VIDEO') && !content.IsLocked && (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                                  mat-list-icon svgIcon="content_play"
                                  (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                                  class="link">
                                </mat-icon>
    
                                <mat-icon end
                                  *ngIf="(content.Type == 'ADIOSCOPE' || content.Type == 'ADIOSCOPEEMBED') && !content.IsLocked && (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                                  mat-list-icon svgIcon="content_adioscope"
                                  (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                                  class="link">
                                </mat-icon>
    
                                <mat-icon end
                                  *ngIf="(content.Type == 'JOURNALING' || content.Type == 'ESSAY') && 
                                  !content.IsLocked &&
                                  (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                                  mat-list-icon svgIcon="content_journaling"
                                  (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                                  class="link">
                                </mat-icon>
    
                                <mat-icon end
                                  *ngIf="content.IsLocked"
                                  mat-list-icon svgIcon="content_locked">
                                </mat-icon>
                              </mat-list-item>
                            </ng-container>
    
                            <mat-list-item *ngIf="contentGroup.Contents.length == 0" class="content-card">
                              <div mat-line class="content-title">No content for this day. Please contemplate on
                                things that matter</div>
                            </mat-list-item>
    
                          </ng-container>
                        </ng-container>
    
                      </ng-container>
                    </mat-list>
                  </div>
                </swiper-slide>
              </ng-container>
            </swiper-container> -->
          </div>
        </div>
      </div>

      <div class="dflex flex-column">
        <button *ngIf="isMember && !programParticipant" class="btn btn-success w-100 text-white" type="button"
          (click)="startProgram()">
          <span *ngIf="startingProgram" class="spinner-border spinner-border-lg" role="status"
            aria-hidden="true"></span>
          <span *ngIf="!startingProgram">TRY WEEK 1 FOR FREE</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ion-modal #joincohortbox trigger="open-modal">
  <ng-template>
    <div class="modal-container w-100 h-100">
      <sb-join-program class="dflex w-100 h-100" (onsubmit)="batchJoined($event)"></sb-join-program>
    </div>
  </ng-template>
</ion-modal>

<ion-modal #contentFlowPopupbox trigger="open-modal" (willDismiss)="closeCurrentContent($event, contentFlowPopupbox)">
  <div class="modal-container">
    <!-- <sb-content-flow class="dflex w-100 h-100" *ngIf="!modalsetup" #contentFlow [options]="currentContent"
      [contents]="contents" (markCompleted)="markCompleted($event)">
    </sb-content-flow> -->
  </div>
</ion-modal>