import { Injectable, LOCALE_ID } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
// import * as moment from "moment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user.service';
// import { StreamState } from '../interfaces/stream-state';
// import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { format } from 'date-fns';

@Injectable()
export class CurriculumService {
  private state: any = {
    loading: false,
    loaded: false,
    playing: false,
    paused: false,
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    volume: 0.5,
    canplay: false,
    error: false,
    type: ''
  };
  private stop$ = new Subject();
  private audioObj = new Audio();
  private currentTrack: any = {};
  audioEvents = [
    "ended",
    "error",
    "play",
    "playing",
    "pause",
    "timeupdate",
    "canplay",
    "loadedmetadata",
    "loadstart",
    "seeking",
    "seeked"
  ];
  private stateChange: BehaviorSubject<any> = new BehaviorSubject(
    this.state
  );


  private currentTrackObservable: BehaviorSubject<any> = new BehaviorSubject(
    this.currentTrack
  );

  constructor(private userService: UserService,
    // private musicControls: MusicControls,
    private http: HttpClient,) {
      this.audioObj.preload = "auto";
  }

  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case "loadstart":
        this.state.loading = true;
        break;
      case "seeking":
        this.state.seeking = true;
        break;
      case "seeked":
        this.state.seeking = false;
        break;
      case "canplay":
        this.state.loading = false;
        this.state.loaded = true;
        this.state.seeking = false;
        this.state.duration = this.audioObj.duration;
        if(this.state.duration) {
          this.state.readableDuration = this.formatTime(this.state.duration);
        } else {
          this.state.readableDuration = "00:00";
        }
        if (this.state.currentTime) {
          this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        } else {
          this.state.readableCurrentTime = "00:00"
        }
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        this.state.paused = false;
        break;
      case "pause":
        this.state.playing = false;
        this.state.paused = true;
        break;
      case "timeupdate":
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(
          this.state.currentTime
        );
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
    }
    this.state.type = event.type;
    this.stateChange.next(this.state);
  }

  public resetState() {
    this.state = {
      playing: false,
      paused: false,
      readableCurrentTime: '',
      readableDuration: '',
      duration: undefined,
      currentTime: undefined,
      volume: 1,
      canplay: false,
      error: false,
      seeking: false
    };
  }

  getState(): Observable<any> {
    return this.stateChange.asObservable();
  }

  getCurrentTrack(): Observable<any> {
    return this.currentTrackObservable.asObservable();
  }

  private loadObservable(url: any, track?: any) {
    return new Observable(observer => {
      // Play audio
      const token = this.userService.getToken();
      const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
      let obj = url;
      this.currentTrack = track;
      this.audioObj.src = obj;

      this.audioObj.load();

      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      };

      this.addEvents(this.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(this.audioObj, this.audioEvents, handler);
        // reset state
        this.resetState();
      };

    });
  }


  private streamObservable(url: any, track?: any) {
    return new Observable(observer => {
      // Play audio
      console.log("Loading stream");
      const token = this.userService.getToken();
      const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
      let obj = url;

      this.currentTrack = track;
      this.audioObj.src = obj;

      this.audioObj.load();

      this.audioObj.play();

      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      };

      this.addEvents(this.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(this.audioObj, this.audioEvents, handler);
        // reset state
        this.resetState();
      };

    });
  }

  private addEvents(obj: any, events: any, handler: any) {
    events.forEach((event: any) => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj: any, events: any, handler: any) {
    events.forEach((event: any) => {
      obj.removeEventListener(event, handler);
    });
  }

  public unsetTrack(){
    this.currentTrackObservable.next(null);
  }

  public setTrack(track, type){
    track.Type = type;
    this.currentTrackObservable.next(track);
  }


  playContent(content: any) {
    const handler = (event: Event) => {
      this.updateStateEvents(event);
    };

    if(this.audioObj){
      this.audioObj.pause();
      this.audioObj.currentTime = 0;
      // remove event listeners
      this.removeEvents(this.audioObj, this.audioEvents, handler);
      // reset state
      this.resetState();
    }
    // this.preload();
    this.setMediaSessionInfo(content);

    this.audioObj = new Audio(content.Url);
    this.audioObj.preload = "auto";
    this.audioObj.load();
    // this.audioObj.play();
    // Show the pause button.
    if (this.audioObj.readyState === 4) {
      console.log("player loaded");
      this.state.loading = false;
      this.state.loaded = true;
      this.state.seeking = false;
      this.state.duration = this.audioObj.duration;
      this.state.readableDuration = this.formatTime(this.state.duration);
      if (this.state.currentTime) {
        this.state.remainingTime = this.audioObj.duration - this.state.currentTime;
        this.state.readableRemainingTime = this.formatTime(this.state.remainingTime);
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
      } else {
        this.state.readableCurrentTime = "00:00"
      }
      this.state.canplay = true;
    } else {
      this.state.loading = true;
      console.log("player loading");
    }
    this.addEvents(this.audioObj, this.audioEvents, handler);
  }

  setMediaSessionInfo(data){
    
        console.log(this.currentTrack);
        /* Implementation of the Media Session API */
        if('mediaSession' in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
              title: data.Title,
              // album: data.chapterName + " - " + data.lessonName,
          });
          navigator.mediaSession.setActionHandler('nexttrack', null);
          navigator.mediaSession.setActionHandler('previoustrack', null);

          navigator.mediaSession.setActionHandler('play', () => {
            this.play();
          });
          navigator.mediaSession.setActionHandler('pause', () => {
            this.pause();
          });
          navigator.mediaSession.setActionHandler('seekto', (details) => {
            // this.seek(details.seekTime);
            this.seekTo(details.seekTime)
          });
          navigator.mediaSession.setActionHandler('stop', () => {
              this.stop();
          });
        }
    //   }
    // });
  }

  
  loadStream(url: any, track?: any) {
    console.log(url);
    console.log(track);

    return this.loadObservable(url, track).pipe(takeUntil(this.stop$));
  }

  playStream(url: any, track?: any) {
    return this.streamObservable(url, track).pipe(takeUntil(this.stop$));
  }

  play() {
    this.audioObj.play();
  }

  pause() {
    this.audioObj.pause();
  }

  stop() {
    console.log("Stop called");
    this.stop$.next(true);
  }

  seekTo(seconds: any) {
    this.audioObj.currentTime = seconds;
  }

  setVolume(volume: any) {
    this.audioObj.volume = volume;
  }


  formatTime(time: number, format2: string = "mm:ss") {
    // const momentTime = time * 1000;
    // return moment.utc(momentTime).format(format);
    const dt = new Date(time * 1000);
    const date1 = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    return format(date1, format2);
  }
}
